<template>
  <div>
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>

    <el-dialog
      class="check-out-dialog"
      :visible.sync="checkOutVisible"
      width="40%"
    >
      <!-- 第一步 -->
      <div v-if="checkOutOneStep" class="check-out-one-step">
        <div>
          <el-radio v-model="radio" label="1">
            方式1. 按人员住宿信息退房
          </el-radio>
          <el-button @click="downloadTemplate()">下载导入模板</el-button>

          <div class="check-out-one-step-desc">
            上传需要退房的人员住宿信息，批量导入系统退房。
          </div>
        </div>
        <div style="margin-top: 20px; color: #c0c4cc">
          <el-radio disabled v-model="radio" label="2">
            方式2. 按房间信息退房
          </el-radio>
          <div class="check-out-one-step-desc">
            上传需要退房的房间信息，批量导入系统退房。
          </div>
        </div>
        <div class="check-out-one-step-btn">
          <el-button type="primary" @click="nextStep()">下一步</el-button>
        </div>
      </div>
      <!-- 第二步 -->
      <div v-if="checkOutTwoStep">
        <div class="check-out-two-step">
          <div class="check-out-two-step_retuenBtn">
            <el-button
              icon="el-icon-back"
              circle
              @click="returnBtn()"
            ></el-button
            ><span>返回</span>
          </div>
          <div class="check-out-two-step_cont">
            <div style="font-weight: bold; padding-bottom: 10px">
              请上传人员住宿信息，批量退房。
            </div>
            <div class="check-out-two-step-desc">
              注意：单次上传数据不超过2000行，一次只能对一个房源下的房间进行批量退房，不支持跨房源名称。
            </div>
            <div style="margin-top: 20px" v-if="exportLoading">
              <i
                class="el-icon-loading"
                style="font-size: 22px; margin-right: 5px"
              ></i>
              <span>正在上传数据，请稍等...</span>
            </div>
            <div class="up_add_bg" @click="onImgFunBtn">
              上传
              <input
                type="file"
                class="upload-btn"
                @change="addFile"
                ref="inputer"
                id="fileupload"
                multiple="false"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              />
            </div>
            <div>人员信息模板：</div>
            <div style="margin: 10px 0">
              <el-table :data="tempTable" border style="width: 100%">
                <el-table-column
                  prop="houseName"
                  align="center"
                  label="房源名称"
                >
                </el-table-column>
                <el-table-column
                  prop="buildingName"
                  align="center"
                  label="楼栋名称"
                >
                </el-table-column>
                <el-table-column prop="room" align="center" label="房间">
                </el-table-column>
                <el-table-column prop="name" align="center" label="姓名">
                </el-table-column>
                <el-table-column
                  prop="studentId"
                  align="center"
                  label="学号/职工号"
                  width="140"
                >
                </el-table-column>
              </el-table>
            </div>
            <div class="check-out-two-step-desc">
              <div class="check-out-two-step-desc-row">
                1.单次批量导入的房源名称必须一致；
              </div>
              <div class="check-out-two-step-desc-row">
                2.单次批量导入数据不超过2000行；
              </div>
              <div class="check-out-two-step-desc-row">
                3.以上表格房源名称、楼栋名称、房间必须与系统中保持一致，不允许为空；
              </div>
              <div class="check-out-two-step-desc-row">
                4..姓名、学号/职工号为必填。
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "BatchrCheckOutList",
  data() {
    let _this = this;
    return {
      opt: {
        title: "批量退房",
        isNotShowSelection: true, //不显示表格列表多选框
        houseList: [],
        search: [
          {
            key: "date",
            label: "操作退房日期",
            type: "data-range",
          },
          {
            key: "houseName",
            label: "房源名称",
            type: "select",
            opt: {
              list: [],
            },
          },
          {
            key: "userName",
            label: "操作人",
            maxlength: 24,
          },
        ],
        columns: [
          {
            label: "批量退房单号",
            key: "id",
          },
          {
            label: "退房房源",
            key: "houseName",
          },
          {
            label: "退房房间（间）",
            key: "roomCount",
          },

          {
            label: "退房人数（人）",
            key: "checkoutUserCount",
          },
          {
            label: "解约租约（条）",
            key: "freeLeaseCount",
          },
          {
            label: "操作人（角色）",
            key: "optionInfo",
          },
          {
            label: "操作退房时间",
            key: "createdDate",
          },
          {
            label: "操作退房结果",
            key: "stateStr",
          },
          {
            label: "操作",
            key: "action",
            type: "action-but",
            opt: {
              list: [
                {
                  label: "查看日志",
                  on(row) {
                    _this.checkLog(row);
                  },
                },
              ],
            },
          },
        ],
        buttons: [
          {
            type: 0,
            name: "批量导入退房",
            on() {
              _this.checkOutVisible = true;
              _this.checkOutOneStep = true;
              _this.checkOutTwoStep = false;
              _this.exportLoading = false;
            },
          },
        ],
      },
      checkOutVisible: false,
      checkOutOneStep: true,
      checkOutTwoStep: false,
      radio: "1",
      exportLoading: false,
      formData: new FormData(),
      file: {}, //文件数据
      fileName: "",
      tempTable: [
        {
          houseName: "",
          buildingName: "",
          room: "",
          name: "",
          studentId: "",
        },
        {
          houseName: "",
          buildingName: "",
          room: "",
          name: "",
          studentId: "",
        },
      ],
    };
  },
  activated() {
    this.getApartmentList();
  },
  created() {},

  methods: {
    onGet(opt) {
      let dto = {
        current: opt.skip,
        pageSize: opt.limit,
        startDate: opt.searchForm["date"] ? opt.searchForm["date"][0] : null,
        endDate: opt.searchForm["date"] ? opt.searchForm["date"][1] : null,
        ...opt.searchForm,
      };
      if (dto.date) {
        delete dto.date;
      }
      if (opt.searchForm.houseName) {
        this.houseList.forEach((item) => {
          if (opt.searchForm.houseName == item.value) {
            dto.houseName = item.label;
          }
        });
      }
      this.post("/tenant-service/lease/v1/batch-checkout/info/page", dto, {
        isUseResponse: true,
      }).then((res) => {
        if (res.data.code == 0 && res.data.data.data.length > 0) {
          res.data.data.data.forEach((item) => {
            if (item.state == 2) {
              item.stateStr =
                "已完成" + item.checkoutSuccessCount + ",失败" + item.checkoutFailCount;
            } else {
              item.stateStr = ["准备中", "处理中"][item.state];
            }
            if (item.roleName) {
              item.optionInfo = item.userName + "(" + item.roleName + ")";
            } else {
              item.optionInfo = item.userName;
            }
            item.roomCount = item.roomCount || '--';
            item.checkoutUserCount = item.checkoutUserCount || '--';
            item.freeLeaseCount = item.freeLeaseCount || '--';
          });
        }
        opt.cb(res.data.data);
      });
    },
    //获取房源列表
    getApartmentList() {
      var dto = {
        pageNumber: "1",
        pageSize: "99999",
        landlordId:
          this.cache.getLS("userInfo")["roleType"] == "1"
            ? null
            : this.cache.getLS("userInfo")["userId"],
      };
      return new Promise((resolve, reject) => {
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          (res) => {
            let returnData = {};
            res.forEach((data) => {
              returnData[data.id] = data.name;
            });
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            this.opt.search.forEach((item) => {
              if (item.key == "houseName") {
                item.opt.list = res;
              }
            });
            this.houseList = res;
            resolve(returnData);
          }
        );
      });
    },
    checkLog(item) {
      this.$router.push({
        path: "/main/batchrCheckOut/logDetail",
        query: {
          id: item.id,
          checkoutUserCount: item.checkoutUserCount ? item.checkoutUserCount : 0,
          checkoutSuccessCount: item.checkoutSuccessCount ? item.checkoutSuccessCount : 0,
          checkoutFailCount: item.checkoutFailCount ? item.checkoutFailCount : 0,
          createdDate: item.createdDate ? item.createdDate : "--",
          state: item.state,
        },
      });
    },
    nextStep() {
      this.checkOutOneStep = false;
      this.checkOutTwoStep = true;
    },
    returnBtn() {
      this.checkOutOneStep = true;
      this.checkOutTwoStep = false;
    },
    downloadTemplate() {
      // 下载模板
      window.open("http://oss-image-prod.wfyundata.com/wave-file/20241029/1730193081163/批量导入退房模板.xlsx");
    },
    onImgFunBtn() {
      document.getElementById("fileupload").click();
    },
    //选择文件
    addFile: function (e) {
      var _this = this;
      let inputDOM = this.$refs.inputer;
      this.file = inputDOM.files[0];
      let len = this.file.length;
      let size = Math.floor(this.file.size / 1024);
      if (size > 50 * 1024 * 1024) {
        alert("请选择50M以内的文件！");
        return false;
      }
      this.formData.append("multipartFile", this.file);
      this.exportLoading = true;
      axios({
        method: "POST",
        url:
          _this.UPLOAD_URL + "/tenant-service/lease/v1/batch-checkout/batch/checkout",
        data: this.formData,
        headers: {
          accessToken:
            localStorage.getItem("token") || sessionStorage.getItem("token"),
          securityKey:
            localStorage.getItem("securityKey") ||
            sessionStorage.getItem("securityKey"),
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          this.formData = new FormData();
          this.fileName = document.getElementById("fileupload").files[0].name;
          if (response.data.code == 0) {
            this.$message({
              type: "success",
              message: "导入成功!",
            });
            this.checkOutVisible = false;
            // 刷新列表
            this.$refs.myList.onRefresh();
          } else {
            this.$message({
              type: "warning",
              message: response.data.msg,
            });
          }
          e.target.value = ""; //清空input中的value值 避免同一个文件二次上传无效的问题
          this.exportLoading = false;
        })
        .catch((error) => {
          this.formData = new FormData();
          e.target.value = ""; //清空input中的value值 避免同一个文件二次上传无效的问题
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.check-out-dialog {
  font-size: 14px;
}
.check-out-one-step,
.check-out-two-step {
  padding: 0 20px;
}
.check-out-two-step_cont {
  padding-left: 50px;
}
.check-out-one-step-desc {
  margin-left: 27px;
  margin-top: 10px;
}
.check-out-one-step-btn {
  margin-top: 30px;
  text-align: center;
}

.check-out-two-step-desc {
  color: red;
  font-size: 12px;
  .check-out-two-step-desc-row {
    padding: 2px 0;
  }
}
.check-out-two-step_retuenBtn {
  margin-bottom: 20px;
  span {
    margin-left: 10px;
  }
}
.up_add_bg {
  margin: 15px 0;
  width: 160px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  background: #30bac1;
  border: 1px solid #30bac1;
  font-size: 14px;
  color: #ffffff;
  border-radius: 6px;
  cursor: pointer;

  .upload-btn {
    opacity: 0;
  }
}
</style>